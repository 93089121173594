import masterData from './data/master-data.json';
import FeaturedCourse from "./components/FeaturedCourse";
import LearningExperience from "./components/LearningExperience";
export default () => {
    return (
        <>
        <section className="hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="hero_text">
                            <h2><span style={{color: "blue"}}> Master</span><span style={{color: "red"}}>Up</span> the
                                programming, cloud and AI/ML </h2>
                            <p>Unlock Your Potential, Anytime, Anywhere: Empowering Minds Through Online Learning </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="hero_img">
                            <div className="hero_img_ani" id="scene">
                                <img src="images/hero-men.png" alt="" data-depth="0.20" className="layer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero_ellipse_icon">
                <img className="ellipse1" src="images/ellipse1.png" alt="" />
                    <img className="ellipse2" src="images/ellipse2.png" alt="" />
                        <img className="ellipse3" src="images/ellipse3.png" alt="" />
                            <img className="ellipse4" src="images/ellipse4.png" alt=""/>
                                <img className="ellipse5" src="images/ellipse5.png" alt=""/>
                                    <img className="ellipse6" src="images/ellipse6.png" alt=""/>
                                        <img className="ellipse7" src="images/ellipse7.png" alt=""/>
                                            <img className="ellipse8" src="images/ellipse8.png" alt=""/>
            </div>
        </section>
            {
                <LearningExperience />
            }
    <section className="feat-course-section">
        <div className="container">
            <div className="base-header">
                <h3> Our Featured Courses </h3>
            </div>

            <div className="row">
                {
                    masterData.featuredCourses.map((course, index) => {
                        return <FeaturedCourse key={index} {...course} />
                    })
                }
            </div>
        </div>
    </section>
        </>
    )
}
