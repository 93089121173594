import {Link} from "react-router-dom";

export default () => {
    return(
        <footer className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        <div className="footer_logo">
                            <img src="images/logo.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="foo_widget quick_lnk">
                            <ul>
                                <li>
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li>
                                    <Link to={"/about"}>About</Link>
                                </li>
                                <li>
                                    <Link to={'/contact'}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="footer_socil">
                            <ul>
                                <li>
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <p> Copyright © 2023 <Link to={"/"}>Masterup.</Link> All rights Reserved.</p>
            </div>
        </footer>
    )
}
