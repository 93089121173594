import masterData from './data/master-data.json';
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const mobileRegex = /^[0-9]{10}$/;
export default () => {
    const reCaptchaRef = React.useRef(null);
    const initialFormValue = {
        name: '',
        email: '',
        mobile: '',
        message: ''
    };
    const { contact } = masterData;
    const [captcha, setCaptcha] = React.useState(null);
    const [formValue, setFormValue] = React.useState(initialFormValue);
    const [formError, setFormError] = React.useState({
        name: false,
        email: false,
        mobile: false,
        message: false,
        captcha: false
    });
    function handleFormChange(event) {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
        setFormError({
            ...formError,
            [event.target.name]: false
        });
    }
    function onChange(value) {
        setCaptcha(value);
        setFormError({
            ...formError,
            captcha: false
        });
    }

    function validateForm(){
        const {name, email, mobile, message} = formValue;
        if(name === '') {
            setFormError({
                ...formError,
                name: true
            });
            return false;
        }
        if(email === '') {
            setFormError({
                ...formError,
                email: true
            });
            return false;
        }
        if(!validEmailRegex.test(email)) {
            setFormError({
                ...formError,
                email: true
            });
            return false;
        }
        if(mobile === '') {
            setFormError({
                ...formError,
                mobile: true
            });
            return false;
        }
        if(!mobileRegex.test(mobile)) {
            setFormError({
                ...formError,
                mobile: true
            });
            return false;
        }
        if(message === '') {
            setFormError({
                ...formError,
                message: true
            });
            return false;
        }
        if(captcha === null) {
            setFormError({
                ...formError,
                captcha: true
            });
            return false;
        }
        return true;
    }
    function submitContact() {
        if(validateForm()) {
           fetch('https://api.getmasterup.com/user/contact', {method: 'POST', body: JSON.stringify(formValue)});
           alert('Thank you for contacting us. We will get back to you soon.');
           setFormValue(initialFormValue);
           reCaptchaRef?.current?.reset();
        }
    }

    return (
        <>
            <header className="single-header">
                <div className="container">
                    <div className="row text-center wow fadeInUp" data-wow-delay="0.5s">
                        <div className="col-sm-12">
                            <h3>Contact Us</h3>
                        </div>
                    </div>
                </div>
            </header>
            <section className="contact-section">
                <div className="container">
                    <div className="contact_wrp">
                        <div className="row">
                            <div className="col-md-5 col-lg-4 col-sm-12">
                                <div className="contact_pg_address">
                                    <h3>Hello there <br/> any questions?</h3>
                                    <div className="single_con_add">
                                        <a href="#"><i className="pe-7s-mail-open-file"></i></a>
                                        <p>Email Address : </p>
                                        {
                                            contact.email.map((email, index) => {
                                                return <span key={index}>{email}</span>
                                            })
                                        }
                                    </div>
                                    <div className="single_con_add">
                                        <a href="#"><i className="pe-7s-headphones"></i></a>
                                        <p>Contact Us :</p>
                                        {
                                            contact.phone.map((phone, index) => {
                                                return <span key={index}>{phone}</span>
                                            })
                                        }
                                    </div>
                                    <div className="contact_social">
                                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                        <a href="#"><i className="fab fa-instagram"></i></a>
                                        <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-8 col-sm-12 inner-contact">
                                <div className="contact-form">
                                    <h3>Send Us Message</h3>
                                    <form method="post" action="mailer.php" id="contact-form">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <input className="con-field" name="name" id="name" type="text"
                                                       placeholder="Name" onChange={handleFormChange}/>
                                                <span className={"error-div"}>{formError.name ? 'Please enter name':' '}</span>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input className="con-field" name="email" id="email2" type="text"
                                                       placeholder="Email" onChange={handleFormChange}/>
                                                <span className={"error-div"}>{formError.email ? 'Please enter valid email':' '}</span>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <input className="con-field" name="mobile" id="phone" type="text"
                                                       placeholder="Phone" onChange={handleFormChange}/>
                                                <span className={"error-div"}>{formError.mobile ? 'Please enter valid phone':' '}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                        <textarea className="con-field" name="message" id="message2" rows="6"
                                                  placeholder="Your Message" onChange={handleFormChange}></textarea>
                                                <span className={"error-div"}>{formError.message ? 'Please enter message':' '}</span>
                                                <div className="submit-area">
                                                    <div className={"captcha-box"}>
                                                        <ReCAPTCHA
                                                            sitekey="6LfyUFwmAAAAAGOw1SJeD2kw6347n9wsK-ju5IcD"
                                                            onChange={onChange}
                                                            ref={reCaptchaRef}
                                                        />
                                                        <span className={"error-div"}>{formError.captcha ? 'Please click captcha':' '}</span>
                                                    </div>
                                                    <input type="button" className="submit-contact" value="submit Now" onClick={submitContact}/>
                                                        <div id="msg" className="message"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
