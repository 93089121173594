import {Link, Navigate, useParams} from "react-router-dom";
import masterData from './data/master-data.json';
import {useEffect, useState} from "react";
export default (props) => {
   const {id} =  useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
   const course = masterData[id];
   const upcomingCourses = masterData.featuredCourses.filter((item) => item.id !== id)[0].batches.upcoming;
    if(!course){
        return <Navigate to={"/NotFound"} replace={true} />;
    }
    const [batch, setBatch] = useState(upcomingCourses[0]);
    const renderPrice = (cost) => {
        if(cost.discount) {
            return (
                <div>
                    INR
                    <span className="feat_cour_p">{(cost.base - cost.discount)}</span>
                    <span className="old_price">({cost.base})</span>
                </div>
            )
        } else {
            return (
                <div>
                    INR
                    <span className="feat_cour_p">{cost.base}</span>
                </div>
            )
        }
    }
    return(
        <>
            <header className="single-header">
                <div className="container">
                    <div className="row text-center wow fadeInUp" data-wow-delay="0.5s">
                        <div className="col-sm-12">
                            <h3>{course.title}</h3>
                        </div>
                    </div>
                </div>
            </header>
            <div className="single_course">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="sing_course_wrap">
                                <div className="sin_course_img">
                                    <img className="img-responsive" src="images/blog1.jpg" alt="" />
                                    <span>{course.tags}</span>
                                </div>
                                <h4>{course.title}</h4>
                                <div className="course_tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <button className="nav-link active" id="pills-discription-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#pills-discription" role="tab"
                                                    aria-controls="pills-discription"
                                                    aria-selected="true" type="button">Why Cloud and AWS?
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link" id="pills-curriculum-tab" data-bs-toggle="tab"
                                                    data-bs-target="#pills-curriculum" role="tab"
                                                    aria-controls="pills-curriculum"
                                                    aria-selected="false" type="button">What you learn?
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content course_tab_cont" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-discription"
                                             aria-labelledby="pills-discription-tab" role="tabpanel">
                                            <p>
                                            {course.description.header}
                                            </p>
                                            <ol className="text-justify">
                                            {
                                                course.description.body.map((item, index) => {
                                                    return <li key={index}>{item}</li>
                                                })
                                            }
                                            </ol>
                                        </div>
                                        <div className="tab-pane fade" id="pills-curriculum"
                                             aria-labelledby="pills-curriculum-tab"
                                             role="tabpanel">
                                            <p>
                                                {course.curriculum.header}
                                            </p>
                                            <ol className='text-justify'>
                                            {
                                                course.curriculum.body.map((item, index) => {
                                                    return <li key={index}>{item}</li>
                                                })
                                            }
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 single_curs_right">
                            <aside className="widget-course-details">
                                <h2> Price  {renderPrice(course.cost)} </h2>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-user"></i>Instructor </span>
                                    <span> {course.instructor} </span>
                                </div>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-clock"></i>Duration </span>
                                    <span> 1 hour each </span>
                                </div>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-journal-whills"></i>Lectures </span>
                                    <span> {course.length} </span>
                                </div>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-layer-group"></i>Level </span>
                                    <span> {course.level} </span>
                                </div>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-book-spells"></i>Prerequisites </span>
                                    <span> {course.prerequisites} </span>
                                </div>
                                <div className="course-detail-list">
                                    <span> <i className="far fa-book-spells"></i>Available Batchs </span>
                                    <span>
                                        <select className="form-select" value={batch} onChange={(event) => {
                                            setBatch(event.target.value);
                                        }}>
                                        {
                                            upcomingCourses.map((item, index) => (<option key={index} className="badge bg-primary me-2">{item}</option>))
                                        }
                                        </select>
                                    </span>
                                </div>
                                <Link to={`/enroll?course=${id}&batch=${batch}`} className="more-link"> Enroll </Link>
                                <div className="share_course">
                                    <span> Share Course : </span>
                                    <a href="#" className="fab fa-facebook-f"></a>
                                    <a href="#" className="fab fa-pinterest"></a>
                                    <a href="#" className="fab fa-twitter"></a>
                                    <a href="#" className="fa fa-link"></a>
                                </div>
                            </aside>
                            <aside className="widget-rel-course">
                                <h3>Related Courses </h3>
                                {
                                    masterData.featuredCourses.map((item, index) => {
                                        return (
                                            <div className="rel-course-box" key={index}>
                                                <Link to={`/${item.id}`}>{item.title}</Link>
                                                <span> {item.tags} </span>
                                            </div>
                                        )
                                    })
                                }
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
