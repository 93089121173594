import {Link} from "react-router-dom";
export default (props) => {
    const renderPrice = (cost) => {
        if(cost.discount) {
            return (
                <div className="feat_cour_price">
                    INR
                    <span className="feat_cour_p">{(cost.base - cost.discount)}</span>
                    <span className="old_price">({cost.base})</span>
                </div>
            )
        } else {
            return (
                <div className="feat_cour_price">
                    INR
                    <span className="feat_cour_p">{cost.base}</span>
                </div>
            )
        }
    }
    const lastClosedBatch = props.batches.closed[props.batches.closed.length - 1];
    const nextAvailableBatch = props.batches.upcoming[0];
    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="feat_course_item">
                <img src={props.image} alt="image" />
                <div className="feat_cour_price">
                    <span className="feat_cour_tag"> {props.tags} </span>
                    <span className="feat_cour_p">{renderPrice(props.cost)}</span>
                </div>
                <h4 className="feat_cour_tit"> {props.title} </h4>
                <div className="feat_cour_lesson">
                    <span className="feat_cour_less"> <i className="pe-7s-note2"></i> {props.length} lessons </span>
                    <span className="feat_cour_stu"> <i
                        className="pe-7s-add-user"></i> {props.strengthPerBatch} Students/Batch </span>
                </div>
                <div>
                    <span className={"sold-out"}>{lastClosedBatch} (Full)</span>
                    <span className={"open-reg"}><Link to={`/enroll?course=${props.id}&batch=${nextAvailableBatch}`}>{nextAvailableBatch} (Enroll) </Link></span>
                </div>
                <div className="feat_cour_rating">
                            <span className="feat_cour_rat">
                                5.0
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                (1)
                            </span>
                    <Link to={`/${props.id}`}> <i className="arrow_right"></i></Link>
                </div>
            </div>
        </div>)
}
