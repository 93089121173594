import masterData from "./data/master-data.json";
import capitalize from 'capitalize';
export default () => {
    const {privacy_policy} = masterData;
    return(
        <section className="account-section">
            <h3>Privacy Policy</h3>
            <div className="container text-justify">
                <ul>
                    {
                        Object.keys(privacy_policy).map((key, index) => {
                            return <li key={index}><h4>{capitalize.words(key)}</h4>
                                <ol>
                                    {
                                        Object.keys(privacy_policy[key]).map((point, index) => {
                                            return <li key={index}>{privacy_policy[key][point]}</li>
                                        })
                                    }
                                </ol>
                            </li>
                        })
                    }
                </ul>
            </div>
        </section>
    )
}
