import {Link} from "react-router-dom";

export default () => {
    return (
        <section className="notfound-section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="notfound_wrap">
                            <img src="images/about.png" alt="" />
                                <h2> Oops... Page Not Found!</h2>
                                <p> Sorry the page could not be found here</p>
                                <Link to={"/"} className="more-link"> Back to home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
