import {Link, useLocation} from "react-router-dom";
import masterData from "./data/master-data.json";
import React, {useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const mobileRegex = /^[0-9]{10}$/;

export default () => {
    const captchaRef= React.useRef(null);
    const initialFormValue = {
        name: '',
        email: '',
        mobile: '',
        organization: '',
        city: '',
        course: '',
        batch: '',
        paymentReference: ''
    }
    const initialFormError = {
        name: false,
        email: false,
        mobile: false,
        organization: false,
        captcha: false
    }
    const [formError, setFormError] = React.useState(initialFormError);
    const [formValue, setFormValue] = React.useState(initialFormValue);
    const [enrollSuccess, setEnrollSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const query = useQuery();
    const selectedCourse = query.get('course');
    const batch = query.get('batch');
    const courses = masterData.featuredCourses.map((course, index) => {
       return <option key={index} value={course.id} selected={course.id === selectedCourse}>{course.title} - {batch}</option>
    });
    const handleFormChange = (event) => {
        setEnrollSuccess(false);
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
        setFormError({
            ...formError,
            [event.target.name]: false
        });
    }
    useEffect(() => {
       if(selectedCourse) {
           setFormValue({
               ...formValue,
               course: selectedCourse,
               batch: batch
           })
       }
       window.scroll(0,0);
    }, []);
    const validateForm = () => {
        const {name, email, mobile, organization, city, course} = formValue;
        if(name === '') {
            setFormError({  ...formError, name: true });
            return false;
        }
        if(email === '') {
            setFormError({  ...formError, email: true });
            return false;
        }
        if(!validEmailRegex.test(email)) {
            setFormError({  ...formError, email: true });
            return false;
        }
        if(mobile === '') {
            setFormError({  ...formError, mobile: true });
            return false;
        }
        if(!mobileRegex.test(mobile)) {
            setFormError({  ...formError, mobile: true });
            return false;
        }
        if(organization === '') {
            setFormError({  ...formError, organization: true });
            return false;
        }
        if(captcha === null) {
            setFormError({  ...formError, captcha: true });
            return false;
        }
        return true;
    }
    const signUpUser = async (event) => {
        if(validateForm()){
           setLoading(true);
            const {name, email, mobile, organization, city} = formValue;
            const data = {
                name,
                email,
                mobile,
                organization,
                city,
                course: selectedCourse,
                batch
            }
            try {
                const response = await fetch('https://api.getmasterup.com/user/enroll', {
                    method: 'POST',
                    body: JSON.stringify(data)
                });
                captchaRef?.current?.reset();
                setEnrollSuccess(true);
                setFormValue(initialFormValue);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                alert('Something went wrong. Please try again');
            }
        }
    }
    function onChange(value) {
        setCaptcha(value);
        setFormError({
            ...formError, captcha: value ? false: true
        });
    }
    return (
        <section className="account-section">
            <div className="container">
                {
                    enrollSuccess && <div className="alert alert-primary" role="alert">
                        Enrolled successfully! Please watch email/message for details.
                    </div>
                }
                <div className="row">
                    <div className="col">
                        <div className="reg_wrap">
                            <div className="reg_img">
                                <img src="images/hero-men.png" alt="" />
                            </div>
                            <div className="registration-form">
                                <h2> Enroll to learn </h2>
                                <form method="post">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 cat_selectbox" style={{marginBottom: "20px"}}>
                                            <select className="form-select" name="course" id="course" value={formValue.course} onChange={handleFormChange} disabled={true}>
                                                {
                                                    courses
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <input className="signup-field" name="name" id="fname" type="text" placeholder="Full Name"
                                                   value={formValue.name} onChange={handleFormChange} />
                                            <span className={"error-div"}>{formError.name ? 'Please enter name':' '}</span>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <input className="signup-field" name="email" id="remail" type="text"
                                                   placeholder="Email address" value={formValue.email} onChange={handleFormChange} />
                                            <span className={"error-div"}>{formError.email ? 'Please enter valid email':' '}</span>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <input className="signup-field" name="organization" id="organization" type="text"
                                                   placeholder="College/Organization" value={formValue.organization} onChange={handleFormChange} />
                                            <span className={"error-div"}>{formError.organization ? 'Please enter College/Organization':''}</span>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <input className="signup-field" name="city" id="city" type="text"
                                                   placeholder="Town/City/Village" value={formValue.city} onChange={handleFormChange} />
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <input className="signup-field" name="mobile" id="mobile" type="text"
                                                   placeholder="Mobile" value={formValue.mobile} onChange={handleFormChange} />
                                            <span className={"error-div"}>{formError.mobile ? 'Please enter valid mobile':''}</span>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="term-and-condition">
                                                <input type="checkbox" id="term" />
                                                <label htmlFor="term">I agree to <Link to={'/terms'}>term &amp; condition</Link> and <Link to={'/privacy'}>privacy policy</Link></label>
                                            </div>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6LfyUFwmAAAAAGOw1SJeD2kw6347n9wsK-ju5IcD"
                                            onChange={onChange}
                                            ref={captchaRef}
                                        />
                                        <span className={"error-div"}>{formError.captcha? 'Please click captcha':''}</span>
                                        <div className="col-sm-12 submit-area">
                                            <a href="#" onClick={signUpUser} className="submit more-link" disabled={loading}> Sign Up </a>
                                            <div id="msg" className="message"></div>
                                        </div>
                                        <p>
                                            *We will contact you to confirm your enrollment and process of payment once you enroll using this form.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
}
