import {Link} from "react-router-dom";
import masterData from "./data/master-data.json";
export default () => {
    const {featuredCourses} = masterData;
    return (
        <>
            <div className="navigation">
                <div className="container">
                    <div className="logo">
                        <Link to={"/"}><img className="img-responsive" src="images/logo.png" alt="" /></Link>
                    </div>
                    <div id="navigation" className="menu-wrap">
                        <ul>
                            <li><Link to={'/about'}>About</Link></li>
                            <li className="has-sub"><Link to={"/"}>Courses</Link>
                                <ul>
                                    {
                                        featuredCourses.map((course, index) => {
                                            return (
                                                <li key={index}><Link to={`/${course.id}`}>{course.title}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                            <li><Link to={'/contact'}>Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
  );
}
