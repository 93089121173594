import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import { Routes, Route} from "react-router-dom";
import Cource from "./Cource";
import About from "./About";
import Contact from "./Contact";
import Enroll from "./Enroll";
import Terms from "./Terms";
import Policy from "./Policy";
import NotFound from "./NotFound";
function App() {
  return (
    <div className="App">
        <Routes>
            <Route index element={<><Header /><Home /> <Footer/></>} />
            <Route path={'/about'} element={<><Header /><About/> <Footer/></>} />
            <Route path={'/contact'} element={<><Header /><Contact/> <Footer/></>} />
            <Route path={'/enroll'} element={<><Header /><Enroll /> <Footer/></>} />
            <Route path={'/terms'} element={<><Header /><Terms /> <Footer/></>} />
            <Route path={'/privacy'} element={<><Header /><Policy /> <Footer/></>} />
            <Route path={`/:id`} element={<><Header /><Cource /> <Footer/></>} />
            <Route path={'/NotFound'} element={<><Header /><NotFound /> <Footer/></>}/>
        </Routes>
    </div>
  );
}

export default App;
