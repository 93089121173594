export default () => {
    return (
        <section className="workflow-section pt-120">
            <div className="container">
                <div className="base-header text-center">
                    <h3>Your learning partner</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="workflow_item">
                            <i className="pe-7s-notebook"></i>
                            <h4> Learn to differentiate </h4>
                            <p>Learn new skills and upskill yourself to standout among the rest</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="workflow_item">
                            <i className="pe-7s-graph1"></i>
                            <h4> Join the growth bandwagon </h4>
                            <p>Learn latest and greatest technologies to get a dream job or reach a new career height</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="workflow_item">
                            <i className="pe-7s-magic-wand"></i>
                            <h4> Get corporate ready </h4>
                            <p>Real industry approach and projects to get you ready for any challenge</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
