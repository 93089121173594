import LearningExperience from "./components/LearningExperience";
import masterData from './data/master-data.json';
export default () => {
    const about = masterData['about'];
    return (
        <>
            <header className="single-header">
                <div className="container">
                    <div className="row text-center wow fadeInUp" data-wow-delay="0.5s">
                        <div className="col-sm-12">
                            <h3>About Us</h3>
                        </div>
                    </div>
                </div>
            </header>
            <LearningExperience/>
            <section className="about-section about_pg_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="abt_rating">
                                <h4> {about.rating}+</h4>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span> User Rating</span>
                            </div>
                            <div className="about_img" id="scene">
                                <img src="images/about.png" alt="image" className="layer" data-depth="0.28"/>
                            </div>
                            <div className="abt_course">
                                <h4>{about.courses}+</h4>
                                <i className="fa fa-heart"></i>
                                <i className="fa fa-heart"></i>
                                <i className="fa fa-heart"></i>
                                <i className="fa fa-heart"></i>
                                <i className="fa fa-heart"></i>
                                <span> Active Courses</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="about_text text-justify">
                                <h3> We make your learning experience awesome </h3>
                                <p> {about.description.header} </p>
                                <ol className="text-justify">
                                    {
                                        about.description.body.map((point, index) => {
                                            return <li key={index}>{point}</li>
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
